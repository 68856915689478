import { createReducer } from '@reduxjs/toolkit';
import { UserClientsState } from './Models';
import { fetchEditableClients } from 'store/users/editableUserClients/actions';

export const initialState: UserClientsState = {
  clients: {
    count: 0,
    items: [],
    pageIndex: 0,
    pageSize: 0,
  },
  isLoading: true,
  error: null,
};

export const editableUserClients = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchEditableClients.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchEditableClients.fulfilled, (state, action) => {
      state.clients = action.payload;
      state.isLoading = false;
    })
    .addCase(fetchEditableClients.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    });
});
