import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Response,
  Arguments,
} from 'api/users/fetchAvailableUserClients/Models';
import { fetchAvailableUserClients } from 'api/users/fetchAvailableUserClients';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchAvailableClients = createAsyncThunk<
  Response,
  { userId: string } & { args: Arguments },
  AsyncThunkOptions
>(
  'availableUserClients/fetchAvailableClients',
  async (params, { rejectWithValue }) => {
    try {
      return await fetchAvailableUserClients(params.userId, params.args);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const saveFilterStatusAvailableUserClients = createAction<boolean>(
  'availableUserClients/saveFilterStatusUserClients',
);
