import { createReducer } from '@reduxjs/toolkit';
import { AvailableUserClientsState } from './Models';

import { clearUserErrors } from 'store/users/actions';
import {
  fetchAvailableClients,
  saveFilterStatusAvailableUserClients,
} from './actions';

export const initialState: AvailableUserClientsState = {
  count: 0,
  items: [],
  pageIndex: 0,
  pageSize: 0,
  isLoading: true,
  isFilterActive: false,
  error: null,
};

export const availableUserClients = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAvailableClients.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchAvailableClients.fulfilled, (state, action) => {
      return { ...state, ...action.payload, isLoading: false };
    })
    .addCase(fetchAvailableClients.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || 500;
    })
    .addCase(clearUserErrors, (state) => {
      state.error = null;
    })
    .addCase(saveFilterStatusAvailableUserClients, (state, action) => {
      state.isFilterActive = action.payload;
    });
});
