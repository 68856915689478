import { Arguments } from 'api/users/fetchUserClients/Models';
import { Response } from 'api/users/fetchUserClients/Models';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserClients } from 'api/users/fetchUserClients';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchClients = createAsyncThunk<
  Response,
  { userId: string } & { args: Arguments },
  AsyncThunkOptions
>('UserClients/fetchClients', async (params, { rejectWithValue }) => {
  try {
    return await fetchUserClients(params.userId, params.args);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const saveFilterStatusUserClients = createAction<boolean>(
  'userClients/saveFilterStatusUserClients',
);

export const clearUserErrors = createAction('users/clearUserErrors');
