
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Arguments } from 'api/users/fetchUserClients/Models';
import { Response } from 'api/users/fetchUserClients/Models';
import { fetchUserClients } from 'api/users/fetchUserClients';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchEditableClients = createAsyncThunk<
  Response,
  { userId: string } & { args: Arguments },
  AsyncThunkOptions
>(
  'editableUserClients/fetchEditableClients',
  async (params, { rejectWithValue }) => {
    try {
      return await fetchUserClients(params.userId, params.args);
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);
